import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/facilities.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { StaticImage } from "gatsby-plugin-image"

export default function DiscoveryPage(props) {
  return (
    <Layout>
      <Seo title="Discovery in the West" />
      <div class="contentBox">
        <div className="mobile-back-bttn">
          <AniLink cover to="/facilities">
            Back to Facilities
          </AniLink>
        </div>
        <h1 className="facility-title">Discovery in the West</h1>
        <div className="facility-container">
          <div className="facility-front-picture-container">
            <StaticImage
              src="../images/facilities/discovery/discovery-front2.jpg"
              placeholder="blurred"
              alt="frontyard"
            />
          </div>
          <div className="facility-details-container">
            <div className="facility-detail-row">
              <p className="detail-title">EST</p>
              <p className="detail">2017</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">License #</p>
              <p className="detail">547206879</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Description</p>
              <p className="detail">
                4 bed, Specialized (Delayed Egress/Secure Perimeter) Adult
                Residential Facility (18 to 59 years of age) located in a rural
                community in North Visalia.
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Services</p>
              <p className="detail">
                Delayed Egress, Secure Perimeter, 24 hour supervision, Private
                rooms, Window/door chimes, Security Cameras, Social skills
                training Enhanced staffing/experienced staff, Predominantly male
                staff, Supervision by a Licensed Psychiatric Technician/Sex
                Offender Treatment Specialist
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Specialization</p>
              <p className="detail">
                Planning and implementing treatment to developmentally disabled
                individuals who currently are or have a history of being
                involved in criminal activity, mental health problems,
                inappropriate sexual behavior, physical and/or verbal
                aggression, property destruction, violent outbursts, taunting
                and teasing, threats, self-injurious behavior (SIB), compulsive
                eating disorders, AWOL, and are court ordered into the facility.
              </p>
            </div>
          </div>
        </div>

        <h2 className="pictures-title">Facility Pictures</h2>
        <div className="masonry">
          <div className="item">
            <StaticImage
              src="../images/facilities/discovery/discovery-kitchen1.jpg"
              alt="kitchen"
              placeholder="blurred"
              width={400}
              height={400}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/discovery/discovery-back2.jpg"
              alt="backyard"
              placeholder="blurred"
              width={400}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/discovery/discovery-living3.jpg"
              alt="living area"
              placeholder="blurred"
              width={400}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/discovery/discovery-living1.jpg"
              alt="living area"
              placeholder="blurred"
              width={400}
              height={450}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/discovery/discovery-living2.jpg"
              alt="living area"
              placeholder="blurred"
              width={400}
              height={400}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
